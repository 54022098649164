import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { useGetCountNamesByKeywordQuery, useGetCountPeopleByKeywordQuery, useGetNamesByKeywordQuery } from '../../redux/services/lastname'

import DashboardFooter from '../components/DashboardFooter'
import Pagination from '../components/Pagination'
import HeaderJsx from '../components/HeaderJsx'

import Peoples from '../../assets/img/peoples.svg'

const NamesByKeyword = () => {
    const [firstName, setFirstName] = useState('')
    const [keyword, setKeyword] = useState(' ')
    const [page, setPage] = useState(1)

    const { keyword: urlKeyword } = useParams()

    const { data, isLoading } = useGetNamesByKeywordQuery({ keyword, page }, { skip: keyword === ' ' || !keyword })
    const { data: countNames } = useGetCountNamesByKeywordQuery(keyword, { skip: keyword === ' ' || !keyword })
    const { data: countPeoples } = useGetCountPeopleByKeywordQuery(keyword, { skip: keyword === ' ' || !keyword })

    const numberWithCommas = (number) => {
        return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        if (urlKeyword) {
            const splittedKeyword = urlKeyword.split('-')[0]
            setKeyword(splittedKeyword)
        }
    }, [urlKeyword])

    useEffect(() => {
        if (data && data.length > 0) {
            const lowerCaseWithFirstUpper = data[0].value[0] + data[0].value.slice(1).toLowerCase()

            setFirstName(lowerCaseWithFirstUpper)
        }
    }, [data])

    return (
        <div>
            <Helmet>
                <title>{keyword[0].toUpperCase() + keyword.slice(1).toLowerCase()} last names - Full list, statistics, and histories</title>
                <meta
                    name="description"
                    content={`${keyword[0].toUpperCase() + keyword.slice(1).toLowerCase()} last names - there are ${countPeoples?.people ? countPeoples?.people : '276012'} people with ${keyword[0].toUpperCase() + keyword.slice(1).toLowerCase()} origin last names in the USA. The most common ${keyword[0].toUpperCase() + keyword.slice(1).toLowerCase()} last name is ${firstName}. Find more here.`}
                />
            </Helmet>
            <HeaderJsx />
            <div className="search-header second-navigation-wrapper">
                <div className='conteiner second-navigation-names'>
                    <Link to={`/`}>Home</Link>
                    <span className='second-navigation-slash'>/</span>
                    <span className="active">{keyword[0].toUpperCase() + keyword.slice(1).toLowerCase()} Surnames</span>
                </div>
            </div>
            {data && data.length > 0 ? (
                <div className='names-by-keyword-content-container'>
                    <div className='names-by-keyword-content'>
                        <div className='names-by-keyword-table-block'>
                            <div className='names-by-keyword-table-block-header'>
                                <h1 className='names-by-keyword-title'>Full list of {keyword[0].toUpperCase() + keyword.slice(1).toLowerCase()} surnames in the USA.</h1>
                                <span className='names-by-keyword-description'>
                                    There are {countPeoples?.people} people with {keyword[0].toUpperCase() + keyword.slice(1).toLowerCase()} last names in the USA. The most common {keyword[0].toUpperCase() + keyword.slice(1).toLowerCase()} last name is {firstName}.
                                </span>
                            </div>
                            {data && data.map((item, index) => (
                                <div key={item.id} className='names-by-keyword-table-element'>
                                    <div className='names-by-keyword-base-info'>
                                        <span className='names-by-keyword-position'>{page * 10 - 10 + 1 + index}.</span>
                                        <Link style={{ textDecoration: 'none' }} to={`/lastname/${item.value.toLowerCase()}`} key={index}>
                                            <span className='names-by-keyword-name'>{item.value[0] + item.value.slice(1).toLowerCase()}</span>
                                        </Link>
                                    </div>
                                    <div className='names-by-keyword-count'>
                                        <img src={Peoples} alt="" />
                                        <span className='names-by-keyword-people-amount'>{numberWithCommas(item.amount_around_the_world.toString())}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {countNames && countNames.count > 10 && (
                            <Pagination
                                totalCount={countNames.count}
                                currentPage={page}
                                itemsPerPage={10}
                                onPageChange={setPage}
                            />
                        )}
                        <div className='names-by-keyword-pagination-block'></div>
                    </div>
                </div>
            ) : !isLoading && (
                <div className='names-by-keyword-content-container'>
                    <div className='names-by-keyword-content'>
                        <div className='names-by-keyword-table-block'>
                            <div style={{ borderBottom: '0' }} className='names-by-keyword-table-block-header'>
                                <h5 className='names-by-keyword-title'>Names not found</h5>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <DashboardFooter />
        </div>
    )
}

export default NamesByKeyword
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const lastnameApi = createApi({
    reducerPath: 'lastnameApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://family-tree-fastapi-prod-utpn6iuzbq-uc.a.run.app/api/' }),
    endpoints: (builder) => ({
        getLastnameByPrefix: builder.query({
            query: (prefix) => ({
                url: `v1/identities/surnames/find_by_prefix?prefix=${prefix}&limit=20&skip=0`,
                method: "GET"
            }),
        }),
        getLastnameDetails: builder.query({
            query: (lastname) => ({
                url: `v2/identities/surname?surname=${lastname}`,
                method: "GET"
            }),
        }),
        getFirstnameMostPopular: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forename/${firstname}/rank`,
                method: "GET"
            }),
        }),
        getFirstnameDistribution: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forenames/${firstname}/countries_population`,
                method: "GET"
            }),
        }),
        getFirstnameHeight: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forename/${firstname}/height`,
                method: "GET"
            }),
        }),
        getFirstnameRegilion: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forename/${firstname}/religion`,
                method: "GET"
            }),
        }),
        getFirstnameSalary: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forenames/${firstname}/salary?start_task=false`,
                method: "GET"
            }),
        }),
        getFirstnamePolitics: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forename/${firstname}/politics`,
                method: "GET"
            }),
        }),
        getFirstnameYearlyPopulation: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forenames/${firstname}/yearly_population`,
                method: "GET"
            }),
        }),
        getFirstnamePhoto: builder.query({
            query: (firstname) => ({
                url: `v2/utils/forename_photo?forename=${firstname}`,
                method: "GET"
            }),
        }),
        getLastnamePopulations: builder.query({
            query: (lastname) => ({
                url: `v2/identities/surname_populations?name=${lastname}&limit=100&skip=0`,
                method: "GET"
            }),
        }),
        getFirstnamesMostPopular: builder.query({
            query: () => ({
                url: `v2/identities/most_popular?limit=10`,
                method: "GET"
            }),
        }),
        getFirstnamesMostPopularDay: builder.query({
            query: () => ({
                url: `v2/identities/forename_of_the_day`,
                method: "GET"
            }),
        }),
        getLastnameGeneratedData: builder.query({
            query: (lastname) => ({
                url: `v2/identities/surnames_generated_data/${lastname}`,
                method: "GET"
            }),
        }),
        getExtraLastnameGeneratedData: builder.query({
            query: (lastname) => ({
                url: `v2/identities/extra_surnames_generated_data/${lastname}`,
                method: "GET"
            }),
        }),
        getListSimilarNames: builder.query({
            query: (name) => ({
                url: `v2/identities/similar_names?name=${name}`,
                method: "GET"
            }),
        }),
        getNamesByKeyword: builder.query({
            query: ({ keyword, page }) => ({
                url: `v2/identities/find_names_by_keyword?kw=${keyword}&limit=10&skip=${page * 10 - 10}`,
                method: "GET"
            }),
        }),
        getCountNamesByKeyword: builder.query({
            query: (keyword) => ({
                url: `v2/identities/count_names_by_keyword?kw=${keyword}`,
                method: "GET"
            }),
        }),
        getMostPopularSurnames: builder.query({
            query: () => ({
                url: `v2/identities/most_popular_surnames`,
                method: "GET"
            }),
        }),
        getLastNameOfTheDay: builder.query({
            query: () => ({
                url: `v2/identities/surname_of_the_day`,
                method: "GET"
            }),
        }),
        getCountPeopleByKeyword: builder.query({
            query: (keyword) => ({
                url: `v2/identities/count_people_by_keyword?kw=${keyword}`,
                method: "GET"
            }),
        }),
    })
})

export const {
    useGetLastnameByPrefixQuery,
    useGetLastnameDetailsQuery,
    useGetFirstnameMostPopularQuery,
    useGetFirstnameDistributionQuery,
    useGetFirstnameHeightQuery,
    useGetFirstnameRegilionQuery,
    useGetFirstnameSalaryQuery,
    useGetFirstnamePoliticsQuery,
    useGetFirstnameYearlyPopulationQuery,
    useGetFirstnamePhotoQuery,
    useGetLastnamePopulationsQuery,
    useGetFirstnamesMostPopularQuery,
    useGetFirstnamesMostPopularDayQuery,
    useGetLastnameGeneratedDataQuery,
    useGetExtraLastnameGeneratedDataQuery,
    useGetListSimilarNamesQuery,
    useGetNamesByKeywordQuery,
    useGetCountNamesByKeywordQuery,
    useGetMostPopularSurnamesQuery,
    useGetLastNameOfTheDayQuery,
    useGetCountPeopleByKeywordQuery
} = lastnameApi
import React from 'react';

import PaginationArrowLeft from '../../assets/img/paginationArrowLeft.svg';
import PaginationArrowRight from '../../assets/img/paginationArrowRight.svg';

const Pagination = ({ totalCount, currentPage, itemsPerPage, onPageChange }) => {
    const totalPages = Math.ceil(totalCount / itemsPerPage);
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const renderPageNumbers = () => {
        const displayedPages = [];
        const pagesToShow = 3; // Adjust the number of pages to show

        if (totalPages <= pagesToShow) {
            return pageNumbers;
        }

        if (currentPage <= pagesToShow - 1) {
            displayedPages.push(...pageNumbers.slice(0, pagesToShow));
            displayedPages.push('...');
            displayedPages.push(totalPages);
        } else if (currentPage >= totalPages - (pagesToShow - 2)) {
            displayedPages.push(1, '...');
            displayedPages.push(...pageNumbers.slice(totalPages - pagesToShow + 1));
        } else {
            displayedPages.push(1, '...');
            displayedPages.push(...pageNumbers.slice(currentPage - 1, currentPage + 2));
            displayedPages.push('...');
            displayedPages.push(totalPages);
        }

        return displayedPages;
    };

    return (
        <div className='pagination-block'>
            <button style={{ borderRight: '1px solid #EBF1F5' }} className='pagination-arrow-button' onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                <img src={PaginationArrowLeft} alt="" />
            </button>
            {renderPageNumbers().map((number, index) => {
                if (number === '...') {
                    return (
                        <span className='pagination-number-item' key={index}>
                            {number}
                        </span>
                    )
                } else {
                    return (
                        <span className={`pagination-number-item ${currentPage === number ? 'active' : ''} ${totalPages === number ? 'border-0' : ''}`} key={index} onClick={() => onPageChange(number)}>
                            {number}
                        </span>
                    )
                }
            })}
            <button style={{ borderLeft: '1px solid #EBF1F5' }} className='pagination-arrow-button' onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                <img src={PaginationArrowRight} alt="" />
            </button>
        </div>
    );
};

export default Pagination;

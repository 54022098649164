import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import HeaderJsx from './HeaderJsx'

import LinkWhite from '../../assets/img/link-white.svg'
import MenuIcon from '../../assets/img/menu.svg'
import ExploreWhite from '../../assets/img/explore-white.svg'
import PublicRecords from '../../assets/img/PublicRecords.svg';
import DeathRecords from '../../assets/img/DeathRecords.svg';
import Obituaries from '../../assets/img/Obituaries.svg';
import BirthRecords from '../../assets/img/BirthRecords.svg';
import MarriageRecords from '../../assets/img/MarriageRecords.svg';
import DNATesting from '../../assets/img/DNATesting.svg';

const NamesHeader = ({ handleCopy, mainData, extraData }) => {
    const [selected, setSelected] = useState('SummaryScroll')
    const [showMenu, setShowMenu] = useState(false)
    const { lastname } = useParams()

    const navigate = useNavigate()

    const scrollToTop = (element) => {
        document.getElementById(element).scrollIntoView({ behavior: 'smooth' });
        setSelected(element)
    }

    const handleToNavigate = () => {
        navigate('/explore')
    }

    return (
        <>
            <div className='names-header-desktop'>
                <HeaderJsx />
            </div>
            <div className='names-header-mobile'>
                <div className='names-mobile-header-content'>
                    <div className='header-left-side-mobile'>
                        <img onClick={() => setShowMenu(!showMenu)} src={MenuIcon} alt="" />
                        <div className='header-mobile-name-block'>
                            <h3 className='header-mobile-name mb-0'>{lastname[0].toUpperCase() + lastname.slice(1)}</h3>
                            <span className='header-mobile-name-type'>Last Name</span>
                        </div>
                    </div>
                    <div className='header-right-side-mobile'>
                        <img onClick={handleCopy} src={LinkWhite} alt="" />
                        <img onClick={handleToNavigate} src={ExploreWhite} alt="" />
                    </div>
                </div>
                <div className='names-mobile-header-navigation'>
                    {extraData?.short_description && (
                        <Link to="#SummaryScroll"
                            onClick={() => scrollToTop('SummaryScroll')}
                            className={`names-mobile-header-link ${selected === 'SummaryScroll' && 'active'}`}
                            offset={-300}>
                            Summary
                        </Link>
                    )}
                    {extraData?.amount_around_the_world && extraData?.rating_position_worldwide && (
                        <Link to="#OverviewScroll"
                            onClick={() => scrollToTop('OverviewScroll')}
                            className={`names-mobile-header-link ${selected === 'OverviewScroll' && 'active'}`}
                            offset={-300}>
                            Overview
                        </Link>
                    )}
                    <Link to="#RelatedScroll"
                        onClick={() => scrollToTop('RelatedScroll')}
                        className={`names-mobile-header-link ${selected === 'RelatedScroll' && 'active'}`}
                        offset={-300}>
                        Related Surnames
                    </Link>
                    {mainData?.historical_figures && mainData?.historical_figures.length > 0 && (
                        <Link to="#HistoricalFiguresScroll"
                            onClick={() => scrollToTop('HistoricalFiguresScroll')}
                            className={`names-mobile-header-link ${selected === 'HistoricalFiguresScroll' && 'active'}`}
                            offset={-300}>
                            Historical Figures & Celebrities
                        </Link>
                    )}
                    {mainData?.geo && (
                        <Link to="#GenealogyScroll"
                            offset={-300}
                            className={`names-mobile-header-link ${selected === 'GenealogyScroll' && 'active'}`}
                            onClick={() => scrollToTop('GenealogyScroll')}>
                            Geneaology
                        </Link>
                    )}
                    <Link to="#MoreLastNamesScroll"
                        offset={-300}
                        className={`names-mobile-header-link ${selected === 'MoreLastNamesScroll' && 'active'}`}
                        onClick={() => scrollToTop('MoreLastNamesScroll')}>
                        More Last Names
                    </Link>
                    {showMenu && (
                        <div className="menu-block-lastnames-page">
                            <a className="menu-item" href="https://ourpublicrecords.org/public-records/" target="_blank" rel="noreferrer">
                                <img src={PublicRecords} alt="" />
                                Public Records
                            </a>
                            <a className="menu-item" target="_blank" href="https://ourpublicrecords.org/obituaries/" rel="noreferrer">
                                <img src={Obituaries} alt="" />
                                Obituaries
                            </a>
                            <a className="menu-item" href="https://ourpublicrecords.org/death-records/" target="_blank" rel="noreferrer">
                                <img src={DeathRecords} alt="" />
                                Death Records
                            </a>
                            <a className="menu-item" href="https://ourpublicrecords.org/birth-records/" target="_blank" rel="noreferrer">
                                <img src={BirthRecords} alt="" />
                                Birth Records
                            </a>
                            <a className="menu-item" href="https://ourpublicrecords.org/marriage-records/" target="_blank" rel="noreferrer">
                                <img src={MarriageRecords} alt="" />
                                Marriage Records
                            </a>
                            <a className="menu-item" href="https://ourpublicrecords.org/dna-testing/" target="_blank" rel="noreferrer">
                                <img src={DNATesting} alt="" />
                                Dna Testing
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default NamesHeader